<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewJob'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="jobData.title"
              outlined
              dense
              :rules="[validators.required]"
              :label="`${$t('title')}*`"
              :placeholder="$t('title')"
              hide-details="auto"
              maxlength="100"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="jobData.description"
              outlined
              dense
              maxlength="6000"
              hide-details="auto"
              :label="`${$t('description')}*`"
              :placeholder="$t('description')"
              rows="4"
              :rules="[validators.required]"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="jobData.city"
              outlined
              dense
              :rules="[validators.required]"
              :label="`${$t('city')}*`"
              :placeholder="$t('city')"
              hide-details="auto"
              maxlength="100"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="jobData.country"
              outlined
              dense
              maxlength="100"
              hide-details="auto"
              :label="`${$t('country')}*`"
              :placeholder="$t('country')"
              :rules="[validators.required]"
            ></v-text-field> </v-col
        ></v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="jobData.assignedTo"
              outlined
              dense
              :items="assignedToItems"
              :label="`${$t('assignedTo')}*`"
              :placeholder="$t('assignedTo')"
              hide-details="auto"
              :rules="[validators.required]"
            /> </v-col
        ></v-row>

        <v-subheader v-t="'workflow'" />
        <v-row>
          <v-col cols="12">
            <v-alert color="warning" text class="mb-0">
              <p v-t="'anyChangeHereWillNotAffectTheTemplate'" class="font-weight-semibold" />
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="jobData.workflow"
              :items="mappedWorkflowTemplatesChoices"
              :label="`${$t('selectWorkflow')}*`"
              :placeholder="$t('selectWorkflow')"
              item-text="title"
              item-value="id"
              hide-details="auto"
              outlined
              dense
              return-object
              :rules="[validators.required]"
            />
            <div v-if="jobData.workflow" class="text-sm my-2">
              {{ jobData.workflow.internalNote }}
            </div>
          </v-col>
        </v-row>
        <v-row v-if="jobData.workflow">
          <v-col v-if="jobData.workflow.steps" cols="12">
            <draggable
              v-model="jobData.workflow.steps"
              v-bind="dragOptions"
              :scroll-sensitivity="350"
              :force-fallback="true"
              @end="onDragEnd"
              @start="onDragStart"
            >
              <div
                v-for="(step, workflowIndex) in jobData.workflow.steps"
                :key="`Add-Job-Step--${workflowIndex}`"
                class="py-3"
              >
                <v-card outlined class="card--border-primary my-handle">
                  <v-card-title>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="step.name"
                          :label="$t('name')"
                          :placeholder="$t('name')"
                          outlined
                          dense
                          maxlength="100"
                          hide-details="auto"
                          :rules="[validators.required]"
                        >
                          <template #prepend>
                            <v-icon class="cursor-pointer" color="primary">{{ icons.mdiDrag }}</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="step.stepDayLimit"
                          :label="$t('stepDayLimit', { postfix: '' })"
                          outlined
                          dense
                          type="number"
                          min="0"
                          max="365"
                          hide-details="auto"
                          :rules="[validators.minPositiveValue, validators.maxDayValueWithEmptyValue]"
                        >
                          <template #append-outer>
                            <v-btn color="error" x-small @click="deleteStep(jobData.workflow.steps, workflowIndex)">
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="step.description"
                          outlined
                          auto-grow
                          :label="$t('description')"
                          rows="2"
                          hide-details="auto"
                          maxlength="1000"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </div>
            </draggable>
          </v-col>
          <v-col v-if="jobData.workflow.id" cols="12">
            <v-btn v-t="'addStep'" color="primary" block @click="addStep(jobData.workflow.steps)" />
          </v-col>
        </v-row>

        <v-expansion-panels class="mb-3 mt-4" multiple>
          <v-expansion-panel v-for="(expansionPanel, index) in expansionPanels" :key="index">
            <v-expansion-panel-header> {{ $t(expansionPanel.title) }} </v-expansion-panel-header>
            <v-expansion-panel-content v-if="index === 0">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :value="jobData.minSalary"
                    outlined
                    dense
                    step=".01"
                    max="99999999.99"
                    type="number"
                    hide-details="auto"
                    :label="$t('minSalary')"
                    :placeholder="$t('minSalary')"
                    :rules="[validators.minSalaryValue, validators.maxSalaryValue]"
                    @change="setMinSalary($event)"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="jobData.maxSalary"
                    outlined
                    dense
                    step=".01"
                    max="99999999.99"
                    type="number"
                    hide-details="auto"
                    :label="$t('maxSalary')"
                    :placeholder="$t('maxSalary')"
                    :rules="[validators.minSalaryValue, validators.maxSalaryValue]"
                    @change="setMaxSalary($event)"
                  ></v-text-field> </v-col
              ></v-row>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="index === 1">
              <v-row>
                <v-col cols="12">
                  <v-subheader v-t="'basicSettings'" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="jobData.showDescription"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('showJobDescription')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="jobData.emailRequired"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('emailRequired')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="jobData.phoneRequired"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('phoneNumberRequired')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="jobData.preferredCityVisible"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('preferredCityVisible')"
                  ></v-switch>
                  <div v-if="jobData.preferredCityVisible">
                    <v-switch
                      v-model="jobData.preferredCityRequired"
                      hide-details="auto"
                      :label="$t('preferredCityRequired')"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="jobData.attachmentsRequired"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('attachmentsRequired')"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="jobData.applyFormLang"
                    :items="applyFormLanguageChoices"
                    :label="$t('applyFormLanguage')"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-subheader v-t="'consents'" />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="jobData.showTalentPoolConsent"
                    hide-details="auto"
                    class="mt-0"
                    :label="$t('showTalentPoolConsent')"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="jobData.additionalConsents"
                    :items="additionalConsentsChoices"
                    :label="$t('additionalConsents')"
                    :placeholder="$t('additionalConsents')"
                    item-value="id"
                    item-text="title"
                    outlined
                    dense
                    multiple
                    return-object
                    hide-details="auto"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-subheader v-t="'emailTemplate'" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="jobData.applyEmailTemplate"
                    :items="emailTemplatesChoices"
                    :label="$t('applyFormEmailTemplate')"
                    :placeholder="$t('applyFormEmailTemplate')"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="index === 2">
              <v-row>
                <v-col cols="12">
                  <draggable
                    v-model="jobData.questions"
                    v-bind="dragOptions"
                    :scroll-sensitivity="350"
                    :force-fallback="true"
                    @end="onDragEnd"
                    @start="onDragStart"
                  >
                    <div
                      v-for="(q, questionIndex) in jobData.questions"
                      :key="`Add-offer-question--${questionIndex}`"
                      class="py-3"
                    >
                      <v-card outlined class="card--border-primary">
                        <v-card-title>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="q.question"
                                :label="$t('question')"
                                :placeholder="$t('question')"
                                outlined
                                dense
                                maxlength="100"
                                hide-details="auto"
                                :rules="[validators.required]"
                              >
                                <template #prepend>
                                  <v-icon class="cursor-pointer my-handle" color="primary">{{ icons.mdiDrag }}</v-icon>
                                </template>
                                <template #append-outer>
                                  <v-btn
                                    color="error"
                                    x-small
                                    @click="deleteQuestion(jobData.questions, questionIndex)"
                                  >
                                    <v-icon class="me-2" size="16">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                    <span v-t="'delete'" />
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-checkbox
                                v-model="q.required"
                                class="mt-0 pt-0 d-inline-flex"
                                :label="$t('required')"
                                hide-details
                              />
                            </v-col>
                          </v-row>
                        </v-card-title>
                      </v-card>
                    </div>
                  </draggable>
                  <v-col cols="12">
                    <v-btn v-t="'addQuestion'" color="primary" block @click="addQuestion(jobData.questions)" />
                  </v-col>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="!valid || (jobData.workflow && jobData.workflow.steps.length === 0)"
              block
              :loading="loading"
            >
              {{ $t('add') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetJobData" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import {
  maxSalaryValue,
  minSalaryValue,
  required,
  minPositiveValue,
  maxDayValueWithEmptyValue,
} from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline, mdiDrag } from '@mdi/js'
import { nextTick, ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import draggable from 'vuedraggable'
import useDraggableOptions from '@/plugins/draggable'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'
import jobUtils from '@/utils/jobsUtils'

export default {
  components: {
    draggable,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    recruiterChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const { addStep, deleteStep } = workflowTemplateUtils()
    const {
      fetchAdditionalConsentsChoices,
      fetchEmailTemplatesChoices,
      addQuestion,
      deleteQuestion,

      applyFormLanguageChoices,
      additionalConsentsChoices,
      emailTemplatesChoices,
    } = jobUtils()

    const expansionPanels = [
      {
        title: 'extraData',
      },
      {
        title: 'applyForm',
      },
      {
        title: 'questions',
      },
    ]

    const blankJobData = {
      title: '',
      description: '',
      city: '',
      country: '',
      assignedTo: null,
      minSalary: 0,
      maxSalary: 0,
      applyEmailTemplate: null,
      applyFormLang: 'eng',
      attachmentsRequired: true,
      emailRequired: true,
      phoneRequired: false,
      preferredCityRequired: true,
      preferredCityVisible: true,
      showDescription: false,
      showTalentPoolConsent: false,
      additionalConsents: [],
      workflow: null,
      questions: [],
    }

    const { createJob, getAllWorkflowTemplatesDetails } = useActions('jobs', [
      'createJob',
      'getAllWorkflowTemplatesDetails',
    ])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const jobData = ref(_.cloneDeep(blankJobData))

    const assignedToItems = ref([])

    const reArrangeAssignedToItems = () => {
      const assignedToAdmins = [{ header: 'Admin' }]
      const assignedToRecruiters = [{ divider: true }, { header: 'Recruiter' }]
      assignedToItems.value = props.recruiterChoices.filter((user) => !['expert', 'suspended'].includes(user.access))

      assignedToItems.value.forEach((user) => {
        if (user.access === 'admin') {
          assignedToAdmins.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        } else if (user.access === 'recruiter') {
          assignedToRecruiters.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        }
      })
      assignedToItems.value = [...assignedToAdmins, ...assignedToRecruiters]
    }

    const mappedWorkflowTemplatesChoices = ref([])

    const fetchWorkflowTemplates = async () => {
      mappedWorkflowTemplatesChoices.value = []

      const workflowTemplates = await getAllWorkflowTemplatesDetails()
      workflowTemplates.forEach((el) => {
        delete Object.assign(el, { steps: el.stepsTemplate }).stepsTemplate
      })
      mappedWorkflowTemplatesChoices.value.push(
        {
          divider: true,
        },
        { header: 'Templates' },
        ...workflowTemplates,
      )
    }

    const setMinSalary = (value) => {
      jobData.value.minSalary = value
      nextTick(() => {
        jobData.value.minSalary = _.floor(value, 2)
      })
    }
    const setMaxSalary = (value) => {
      jobData.value.maxSalary = value
      nextTick(() => {
        jobData.value.maxSalary = _.floor(value, 2)
      })
    }

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetJobData = () => {
      jobData.value = _.cloneDeep(blankJobData)
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true

        delete jobData.value.workflow.id
        // eslint-disable-next-line no-param-reassign
        jobData.value.workflow.steps.forEach((step) => delete step.id)

        await createJob({ payload: jobData.value })
        loading.value = false
        resetJobData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    watch(
      () => props.isAddNewUserSidebarActive,
      async () => {
        reArrangeAssignedToItems()
        await fetchAdditionalConsentsChoices()
        await fetchEmailTemplatesChoices()
        await fetchWorkflowTemplates()
      },
    )

    return {
      onDragStart,
      onDragEnd,
      resetJobData,
      addStep,
      deleteStep,
      onSubmit,
      setMinSalary,
      setMaxSalary,
      addQuestion,
      deleteQuestion,

      expansionPanels,

      applyFormLanguageChoices,
      additionalConsentsChoices,
      emailTemplatesChoices,
      mappedWorkflowTemplatesChoices,

      dragOptions,
      form,
      loading,
      jobData,
      valid,
      assignedToItems,

      validators: { required, minSalaryValue, maxSalaryValue, minPositiveValue, maxDayValueWithEmptyValue },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
        mdiDrag,
      },
    }
  },
}
</script>
