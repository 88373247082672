<template>
  <v-navigation-drawer
    :value="isExportSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-export-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'export'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-export-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert color="info" text>
              <p v-t="'exportAlert'" class="font-weight-semibold mb-1" />
            </v-alert>
          </v-col>
        </v-row>
        <v-subheader v-t="'includeCandidatesData'" />
        <v-row>
          <v-col
            v-for="(exportField, index) in exportFieldsCandidates"
            :key="`exportFieldsCandidates--${index}`"
            cols="6"
            md="4"
          >
            <v-switch
              v-model="exportField.include"
              hide-details="auto"
              class="mt-0"
              :label="$t(exportField.text, { postfix: '' })"
            ></v-switch>
          </v-col>
        </v-row>
        <v-subheader v-t="'includeCandidatesData'" class="mt-4" />
        <v-row>
          <v-col v-for="(exportField, index) in exportFieldsJobs" :key="`exportFieldsJobs--${index}`" cols="6" md="4">
            <v-switch
              v-model="exportField.include"
              hide-details="auto"
              class="mt-0"
              :label="$t(exportField.text)"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="stage"
              :label="$t('status', { postfix: '' })"
              :items="[
                {
                  text: $t('ongoing'),
                  value: 'ongoing',
                },
                {
                  text: $t('finished'),
                  value: 'finished',
                },
                {
                  text: $t('archived'),
                  value: 'archived',
                },
              ]"
              :rules="[validators.required]"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="jobId"
              :label="$t('job')"
              :items="jobChoices"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="columnNameLanguage"
              :label="$t('columnNameLanguage')"
              :items="[
                {
                  text: $t('english'),
                  value: 'en',
                },
                {
                  text: $t('polish'),
                  value: 'pl',
                },
              ]"
              :rules="[validators.required]"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading">
              {{ $t('download') }}</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              v-t="'discard'"
              color="secondary"
              outlined
              type="reset"
              block
              @click="$emit('update:is-export-sidebar-active', false)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { getCurrentInstance, ref, watch, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'

export default {
  model: {
    prop: 'isExportSidebarActive',
    event: 'update:is-export-sidebar-active',
  },
  props: {
    isExportSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const exportFieldsCandidates = [
      {
        text: 'firstName',
        columnName: 'firstName',
        field: 'candidate_first_name',
        include: true,
      },
      {
        text: 'lastName',
        columnName: 'lastName',
        field: 'candidate_last_name',
        include: true,
      },
      {
        text: 'email',
        columnName: 'email',
        field: 'candidate_email',
        include: true,
      },
      {
        text: 'phoneNumber',
        columnName: 'phoneNumber',
        field: 'candidate_phone',
        include: true,
      },
      {
        text: 'candidateStatus',
        columnName: 'candidateStatus',
        field: 'candidate_status',
        include: true,
      },
      {
        text: 'stepName',
        columnName: 'stepName',
        field: 'candidate_step_name',
        include: true,
      },
      {
        text: 'preferredCity',
        columnName: 'preferredCity',
        field: 'candidate_preferred_city',
        include: true,
      },
      {
        text: 'candidateSource',
        columnName: 'candidateSource',
        field: 'candidate_source',
        include: true,
      },
      {
        text: 'updatedAt',
        columnName: 'updatedAt',
        field: 'candidate_updated_at',
        include: true,
      },
      {
        text: 'createdBy',
        columnName: 'createdBy',
        field: 'candidate_created_by',
        include: true,
      },
      {
        text: 'workingStartDay',
        columnName: 'workingStartDay',
        field: 'candidate_working_start_day',
        include: true,
      },
      {
        text: 'filesCount',
        columnName: 'filesCount',
        field: 'candidate_files_count',
        include: true,
      },
      {
        text: 'questions',
        field: 'candidate_questions',
        include: true,
      },
      {
        text: 'gdprStatus',
        columnName: 'gdprStatus',
        field: 'candidate_gdpr_status',
        include: true,
      },
      {
        text: 'validUntil',
        columnName: 'validUntil',
        field: 'candidate_valid_until',
        include: true,
      },
    ]
    const exportFieldsJobs = [
      {
        text: 'name',
        columnName: 'name',
        field: 'job_name',
        include: true,
      },
      {
        text: 'jobStatus',
        columnName: 'jobStatus',
        field: 'job_status',
        include: true,
      },
      {
        text: 'localization',
        columnName: 'localization',
        field: 'job_localization',
        include: true,
      },
      {
        text: 'minSalary',
        columnName: 'minSalary',
        field: 'job_min_salary',
        include: true,
      },
      {
        text: 'maxSalary',
        columnName: 'maxSalary',
        field: 'job_max_salary',
        include: true,
      },
      {
        text: 'assignedTo',
        columnName: 'assignedTo',
        field: 'job_assigned_to',
        include: true,
      },
      {
        text: 'sharedWith',
        columnName: 'sharedWith',
        field: 'job_shared_with',
        include: true,
      },
      {
        text: 'numberOfSteps',
        columnName: 'numberOfSteps',
        field: 'job_number_of_steps_in_workflow',
        include: true,
      },
    ]

    const vm = getCurrentInstance().proxy

    const { exportJobs, getJobChoices } = useActions('jobs', ['exportJobs', 'getJobChoices'])

    const stage = ref('ongoing')
    const jobId = ref(null)
    const jobChoices = ref([])

    const columnNameLanguage = ref(vm.$i18n.locale)

    const loading = ref(false)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        const exportFields = [
          ...exportFieldsCandidates.filter((field) => field.include),
          ...exportFieldsJobs.filter((field) => field.include),
        ]
        const fields = []
        const columnNames = []
        exportFields.forEach((field) => {
          fields.push(field.field)
          if ('columnName' in field)
            columnNames.push(vm.$t(field.columnName, columnNameLanguage.value, { postfix: '' }))
        })

        const { data, fileName, success } = await exportJobs({
          fields,
          column_names: columnNames,
          stage: stage.value,
          jobId: jobId.value,
        })
        if (success) {
          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`
          anchor.target = '_blank'
          anchor.download = fileName
          anchor.click()
          anchor.remove()
        }

        loading.value = false
        emit('update:is-export-sidebar-active', false)
      } else {
        validate()
      }
    }

    onMounted(async () => {
      jobChoices.value = await getJobChoices({
        candidate: undefined,
        stage: stage.value,
      })
    })

    watch(stage, async (newValue) => {
      jobId.value = null
      jobChoices.value = await getJobChoices({
        candidate: undefined,
        stage: newValue,
      })
    })

    return {
      onSubmit,

      exportFieldsCandidates,
      exportFieldsJobs,
      stage,
      jobId,
      jobChoices,
      columnNameLanguage,
      form,
      loading,
      valid,
      validators: { required },

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
