<template>
  <div id="user-list">
    <job-list-add-new
      v-if="$can(ACTIONS.CREATE, PRIVATE.ATS.JOB)"
      v-model="isAddNewUserSidebarActive"
      :recruiter-choices="recruiterChoices"
      @refetch-data="
        fetchJobs()
        fetchJobsOverview()
      "
    />
    <job-list-export v-if="$can(ACTIONS.EXPORT, PRIVATE.ATS.JOBS)" v-model="isExportSidebarActive" />

    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(total.title) }}</span>
            </div>

            <v-avatar
              :color="resolveJobsTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveJobsTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveJobsTotalIcon(total.title).color" class="rounded-0">
                {{ resolveJobsTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mb-9">
      <v-card-title v-t="'filter'" />
      <v-card-text class="pb-5">
        <v-row>
          <v-col cols="12" sm="3">
            <v-select
              v-model="stageFilter"
              :placeholder="$t('selectStatus')"
              :items="choices.stageChoices"
              outlined
              dense
              clearable
              multiple
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="assignedToFilter"
              :placeholder="$t('selectAssignedTo')"
              :items="choices.recruiterChoices"
              outlined
              dense
              clearable
              multiple
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="cityFilter"
              :placeholder="$t('selectCity')"
              :items="choices.cityChoices"
              outlined
              dense
              clearable
              multiple
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="workflowFilter"
              :placeholder="$t('selectWorkflow')"
              :items="choices.workflowChoices"
              outlined
              dense
              clearable
              multiple
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-12">
      <v-card-text class="d-flex align-center flex-wrap py-5">
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            v-if="$can(ACTIONS.EXPORT, PRIVATE.ATS.JOBS)"
            color="primary"
            class="me-3"
            @click.stop="isExportSidebarActive = !isExportSidebarActive"
          >
            <v-icon class="me-2">{{ icons.mdiExportVariant }}</v-icon>
            <span v-t="'export'" />
          </v-btn>
          <v-btn
            v-if="$can(ACTIONS.CREATE, PRIVATE.ATS.JOB)"
            color="primary"
            class="me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addJob'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="jobs"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalJobsListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
          'items-per-page-options': [10, 50, 250],
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'delete'" class="justify-center text-h5" />
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center">{{
                    $t('confirmationModalUndoneQuestion')
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.workflowTitle`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.city`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.country`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.createdBy`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.candidatesAmount`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.stage`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.title`]="{ item }">
          <div class="d-flex align-center ms-3">
            <v-avatar v-if="item.assignedTo !== null" color="primary v-avatar-light-bg primary--text" size="32">
              <span class="font-weight-medium">{{
                avatarText(`${item.assignedTo.user.firstName} ${item.assignedTo.user.lastName}`)
              }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-ats-job-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title }}
              </router-link>
              <span class="text-xs">{{
                item.assignedTo === null ? '-/-' : `${item.assignedTo.user.firstName} ${item.assignedTo.user.lastName}`
              }}</span>
            </div>
          </div>
        </template>

        <template #[`item.stage`]="{ item }">
          <v-chip
            small
            label
            :color="resolveJobStageColor(item.stage).color"
            :class="`v-chip-light-bg ${
              resolveJobStageColor(item.stage).color
            }--text font-weight-medium text-capitalize`"
          >
            {{ resolveJobStageColor(item.stage).text }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-row justify="center" no-gutters>
            <v-col cols="4">
              <v-tooltip v-if="$can(ACTIONS.DELETE, item)" bottom>
                <template #activator="tooltipActivator">
                  <v-btn
                    icon
                    small
                    color="error"
                    v-bind="tooltipActivator.attrs"
                    v-on="tooltipActivator.on"
                    @click="deleteItem(item.id)"
                  >
                    <v-icon size="18">
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-t="'delete'" />
              </v-tooltip>
            </v-col>

            <v-col cols="4">
              <v-tooltip v-if="$can(ACTIONS.DUPLICATE, item)" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="duplicateHandler(item.id)">
                    <v-icon size="18">
                      {{ icons.mdiContentDuplicate }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-t="'duplicate'"></span>
              </v-tooltip>
            </v-col>

            <v-col cols="4">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    :to="{ name: 'apps-ats-job-view', params: { id: item.id } }"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-t="'view'" />
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiTrashCanOutline, mdiEyeOutline, mdiContentDuplicate, mdiExportVariant } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'
import { useActions } from 'vuex-composition-helpers'
import JobListAddNew from './JobListAddNew.vue'
import JobListExport from './JobListExport.vue'

import useJobList from './useJobList'
import jobUtils from '@/utils/jobsUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  components: {
    JobListAddNew,
    JobListExport,
  },
  setup() {
    const { canDeleteOffer } = jobUtils()

    const { deleteJob, duplicateJob } = useActions('jobs', ['deleteJob', 'duplicateJob'])

    const isDialogVisible = ref(false)
    const isAddNewUserSidebarActive = ref(false)
    const isExportSidebarActive = ref(false)

    const {
      fetchJobs,
      resolveJobStageColor,
      resolveJobsTotalIcon,
      fetchJobsOverview,
      fetchRecruitersChoices,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalJobsListTable,
      loading,
      options,
      overview,
      jobs,
      choices,
      recruiterChoices,
      stageFilter,
      workflowFilter,
      cityFilter,
      assignedToFilter,
    } = useJobList()

    const duplicateHandler = async (jobId) => {
      await duplicateJob(jobId)
      await fetchJobs()
    }

    const itemToDeleteId = ref(null)
    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteJob(itemToDeleteId.value)
        await fetchJobs()
        await fetchJobsOverview()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    onMounted(async () => {
      await fetchJobsOverview()
      await fetchRecruitersChoices()
    })

    return {
      canDeleteOffer,
      fetchJobs,
      duplicateHandler,
      resolveJobStageColor,
      resolveJobsTotalIcon,
      avatarText,
      fetchJobsOverview,
      deleteItem,
      confirmDelete,
      discardDelete,
      delaySearchHandler,

      isDialogVisible,
      tableColumns,
      searchQuery,
      totalJobsListTable,
      loading,
      options,
      isAddNewUserSidebarActive,
      isExportSidebarActive,
      overview,
      jobs,
      choices,
      recruiterChoices,
      stageFilter,
      workflowFilter,
      cityFilter,
      assignedToFilter,

      // icons
      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiEyeOutline,
        mdiContentDuplicate,
        mdiExportVariant,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
